.koopa-maxWidth90 {
  width: 90%;
}

.font-roboto-mono {
  font-family: 'Roboto Mono', monospace;
}

.font-circular {
  font-family: 'Circular Std', Arial, sans-serif;
}

.font-bold {
  font-weight: 700;
}

.printWrapper {
  .noprint {
    visibility: hidden;
  }
}
