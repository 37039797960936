@font-face {
  font-family: 'Circular Std';
  src: url('CircularStd-Medium.eot');
  src: url('CircularStd-Medium.eot?#iefix') format('embedded-opentype'),
    url('CircularStd-Medium.woff2') format('woff2'), url('CircularStd-Medium.woff') format('woff'),
    url('CircularStd-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Circular Std';
  src: url('CircularStd-Book.eot');
  src: url('CircularStd-Book.eot?#iefix') format('embedded-opentype'),
    url('CircularStd-Book.woff2') format('woff2'), url('CircularStd-Book.woff') format('woff'),
    url('CircularStd-Book.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Circular Std';
  src: url('CircularStd-BookItalic.eot');
  src: url('CircularStd-BookItalic.eot?#iefix') format('embedded-opentype'),
    url('CircularStd-BookItalic.woff2') format('woff2'), url('CircularStd-BookItalic.woff') format('woff'),
    url('CircularStd-BookItalic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Circular Std';
  src: url('CircularStd-BoldItalic.eot');
  src: url('CircularStd-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('CircularStd-BoldItalic.woff2') format('woff2'), url('CircularStd-BoldItalic.woff') format('woff'),
    url('CircularStd-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Circular Std';
  src: url('CircularStd-Bold.eot');
  src: url('CircularStd-Bold.eot?#iefix') format('embedded-opentype'),
    url('CircularStd-Bold.woff2') format('woff2'), url('CircularStd-Bold.woff') format('woff'),
    url('CircularStd-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
