/*@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@200;700&family=Roboto:wght@300;400;700&display=swap');*/

@import url('https://fonts.googleapis.com/css2?family=Kalam&display=swap');

@import './assets/font/typo.css';
@import './tailwind-output.css';

html,
body,
#root {
  height: 100%;
  background-color: #e8edef; /* custom grey only use as app background */
}
