.tiptap-editor-container .tiptap-editor {
  width: 100%;
  overflow-y: auto;

  && table {
    border-collapse: collapse;
  }

  && table,
  th,
  td {
    border: 1px solid;
  }
}

.tiptap-editor-container .tiptap-editor:not(.read-only) {
  /* (line height) + (2 * py) + border? */
  height: calc((16px * 1.4375) + (16.5px * 2) + 2px);
  border-radius: 4px;
  resize: vertical;
}

.tiptap-editor-container.variant-default .tiptap-editor:not(.read-only) {
  padding: 16.5px 14px;
}

.tiptap-editor-container.variant-default .tiptap-editor:not(.read-only) {
  border: 1px solid rgba(0, 0, 0, 0.23);
}

.tiptap-editor-container.variant-default .tiptap-editor[contenteditable='true']:hover {
  border: 1px solid rgba(0, 0, 0, 0.87);
}

.tiptap-editor-container.variant-default .tiptap-editor[contenteditable='true'].ProseMirror-focused {
  border: 1px solid rgba(0, 0, 0, 0.23);
  outline: 2px solid #0b3c4f; /* primary.main */
  outline-offset: -1px;
}

.tiptap-editor-container.variant-transparent .tiptap-editor[contenteditable='true'].ProseMirror-focused {
  outline: 0;
}

.tiptap-editor-container.validation-error .tiptap-editor[contenteditable='true'] {
  color: #f41919; /* error.main */
}

.tiptap-editor-container.variant-default.validation-error .tiptap-editor[contenteditable='true'] {
  border: 1px solid #f41919; /* error.main */
}

.tiptap-editor-container.variant-default.validation-error
  .tiptap-editor[contenteditable='true'].ProseMirror-focused {
  outline: 2px solid #f41919; /* error.main */
}

.tiptap-editor-container .tiptap-editor[contenteditable='false']:not(.read-only) {
  color: rgba(0, 0, 0, 0.38);
}

.tiptap-editor-container .tiptap-editor > :first-child {
  margin-top: 0;
}

.tiptap-editor-container .tiptap-editor > :last-child {
  margin-bottom: 0;
}

.tiptap-editor-container .tiptap-editor p {
  margin-top: 0;
  margin-bottom: 0;
}

.tiptap-editor-container .tiptap-editor blockquote {
  border-left: 3px solid #c8ccce; /* customGrey.light */
  margin-left: 0px;
  padding-left: 20px;
}

.ProseMirror p.is-editor-empty:first-child::before {
  color: rgba(0, 0, 0, 0.6);
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}
